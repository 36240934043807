import React, { useEffect } from "react";

// import Custom Components
import Breadcrumb from "../components/common/breadcrumb";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/common/page-header";
import SEO from "../components/common/SEO";

function AboutOne() {
  useEffect(() => {
    document.querySelector(".footer-middle") &&
      document.querySelector(".footer-middle").classList.add("border-0");
  }, []);

  return (
    <MainLayout>
      <div className="main">
        <SEO title="Jimenez Store Mr. Discount - Terms and Conditions Page" />
        <h1 className="d-none">
          Jimenez Store Mr. Discount - Terms and Conditions
        </h1>

        <PageHeader title="Terms and Conditions" />

        {/* <h1 className="d-none">Molla React Ecommerce - </h1> */}

        <Breadcrumb
          title="Terms and Conditions"
          //   parent1={[ "/wholesale"]}
          adClass="border-0 mb-0"
        />

        <div className="page-content pb-0">
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <h2 className="title">Our Vision</h2>
                <p>
                  We have partnered with Mohawk, the largest flooring company in
                  the USA, to offer the widest possible selection at reasonable,
                  discounted prices. With this deal in place, we can offer the
                  broadest selection of inventory to homeowners all over the
                  nation, with extremely low cost but incredibly high quality.
                  Our goal is to make home remodeling affordable for everyone by
                  bringing you the largest selection of home improvement goods
                  at insanely discounted prices!{" "}
                </p>
              </div>

              <div className="col-lg-6">
                <h2 className="title">Our Mission</h2>
                <p>
                  It doesn’t matter if you’re a homeowner, house flipper, or
                  reseller, you’ll be astonished to see the number of products
                  we have in stock and the record low prices attached to them.
                  Offering 30%-80% off prices compared to other average
                  retailers, as well as our impressive stock of appliances,
                  tools, flooring, outdoor furniture, and everything else in
                  between! Not to mention, our inventory is expanding daily!
                  Remodeling your home has never been easier or more affordable.
                  What are you waiting for? Come visit Jimenez today, and snag
                  some hot deals!{" "}
                </p>
              </div>
            </div>

            <div className="mb-5"></div>
          </div> */}

          <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb-3 mb-lg-0">
                  <h2 className="title">THE AGREEMENT:</h2>
                  <p className="lead  mb-3">
                    The use of this website and services on this website and
                    mobile application provided by JIMENEZMRDISCOUNT.com
                    (hereinafter referred to as "Website") are subject to the
                    following Terms & Conditions, all parts and sub-parts of
                    which are specifically incorporated by reference here. This
                    Agreement shall govern the use of all pages on this website
                    (hereinafter collectively referred to as "Website") and any
                    services provided by or on this Website ("Services").
                  </p>
                  <ol type="1" style={{ listStyle: "decimal" }}>
                    <li>
                      {" "}
                      <h4 className="subtitle"> DEFINITIONS:</h4>{" "}
                      <p className="mb-2">
                        “Agreement” denotes to this Terms and Conditions and the
                        Privacy Policy and other documents provided to you by
                        the Website; “We”, “us” and “our” are references to
                        JIMENEZMRDISCOUNT.COM; “User”, “You” and “your” denote
                        the person who is accessing the website for taking or
                        availing any service from us. User shall include the
                        company, partnership, sole trader, person, body
                        corporate or association taking services of this
                        Website; ” Website” shall mean and include
                        jimenezmrdiscount.com and any successor Website of the
                        Company or any of its affiliates; Parties: Collectively,
                        the parties to this Agreement (We and You) will be
                        referred to as Parties.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> ASSENT & ACCEPTANCE:</h4>{" "}
                      <p className="mb-2">
                        By using the Website, you warrant that you have read and
                        reviewed this Agreement and that you agree to be bound
                        by it.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> AGE RESTRICTION</h4>{" "}
                      <p className="mb-2">
                        You must be at least 13 (Thirteen) years of age to use
                        this Website or any Services contained herein. By using
                        this Website, you represent and warrant that you are at
                        least 13 years of age and may legally agree to this
                        Agreement. We assume no responsibility or liability for
                        any misrepresentation of your age.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle">ORDERS & PAYMENTS</h4>{" "}
                      <p className="mb-2">
                        All prices are listed in U.S. Dollars and subject to
                        change without notice. In the event of a pricing error,
                        we reserve the right to refuse or cancel an order
                        placed. Payment is required at time of purchase. We
                        accept all major U.S. credit cards including Visa,
                        MasterCard, American Express, and Discover as well as
                        options like Amazon Pay and PayPal. Once you place your
                        order, your credit card is charged for the amount of
                        your order including tax and shipping charges. You
                        represent and warrant that (i) the payment information
                        you supply to us is true, correct, and complete, (ii)
                        you are duly authorized to use such payment methods for
                        the purchase, (iii) charges incurred by you will be
                        honored by the company assisting you with such payment,
                        such as your credit card company, and (iv) you will pay
                        charges incurred by you at the posted prices, including
                        all applicable taxes, if any. The Site should only list
                        items that are in-stock. However, inventory
                        discrepancies can arise. We reserve the right to correct
                        any errors, inaccuracies, or omissions on our Site at
                        any time without prior notice. If we are unable to
                        deliver your item, we will cancel the order and notify
                        you within 3 business days of the date you placed your
                        order.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle">RETURN POLICY</h4>{" "}
                      <p className="mb-2">
                        <b> Faulty Items</b> <br />
                        You can return your order, or part of it, if damaged,
                        faulty or with an error within 14-days after collecting
                        it from the store. In order to return your item, take
                        your proof of purchase and products that need to be
                        returned to us. The store can then inspect the item, and
                        issue a refund or replacement (if possible). Your proof
                        of purchase can either be your delivery note,
                        confirmation email or invoice.
                        <br />
                        <b> Returning Unwanted Items</b> <br />
                        To return unwanted items to our store, they must be in
                        their new, original condition for re-sale at full
                        value.We reserve the right to refuse returns or to
                        charge you our fees and expenses if the product is not
                        received in a new, unopened condition.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle">GENERAL CONDITION</h4>{" "}
                        <ul>
                          <li>
                            <ul style={{ listStyle: "disc" }}>
                              <li>
                                Photographs are for illustration only. Actual
                                products may vary slightly.
                              </li>
                              <li>
                                We do not guarantee the accuracy, completeness,
                                validity, or timeliness of information listed by
                                us.
                              </li>
                              <li>
                                We make material changes to these terms and
                                conditions from time to time, we may notify you
                                either by prominently posting a notice of such
                                changes or via email communication.
                              </li>
                              <li>
                                The website is licensed to you on a limited,
                                non-exclusive, non-transferable,
                                non-sublicensable basis, solely to be used in
                                connection with the Service for your private,
                                personal, non-commercial use, subject to all the
                                terms and conditions of this Agreement as they
                                apply to the Service.
                              </li>
                            </ul>
                          </li>
                        </ul>
                   
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> INTELLECTUAL PROPERTY</h4>{" "}
                      <p className="mb-2">
                        You agree that the Website and all Services provided by
                        us are the property of JIMENEZMRDISCOUNT.com, including
                        all copyrights, trademarks, trade secrets, patents, and
                        other intellectual property.{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> AGE RESTRICTION</h4>{" "}
                      <p className="mb-2">
                        You must be at least 13 (Thirteen) years of age to use
                        this Website or any Services contained herein. By using
                        this Website, you represent and warrant that you are at
                        least 13 years of age and may legally agree to this
                        Agreement. We assume no responsibility or liability for
                        any misrepresentation of your age.
                      </p>
                    </li>{" "}
                    <li>
                      {" "}
                      <h4 className="subtitle"> ACCEPTABLE USE</h4>{" "}
                      <p className="mb-2">
                        You agree not to use the Website or Services for any
                        unlawful purpose or any purpose prohibited under this
                        clause. You agree not to use the Website or Services in
                        any way that could damage the Website, Services, or
                        general business of JIMENEZMRDISCOUNT.com.{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> ASSUMPTION OF RISK</h4>{" "}
                      <p className="mb-2">
                        The Website and Services are provided for communication
                        purposes only. You acknowledge and agree that any
                        information posted on Our Website is not intended to be
                        legal advice, medical advice, or financial advice, and
                        no fiduciary relationship has been created between you
                        and us. You further agree that your purchase of any of
                        the products on the Website is at your own risk. We do
                        not assume responsibility or liability for any advice or
                        other information given on the Website.{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle">
                        {" "}
                        REVERSE ENGINEERING & SECURITY
                      </h4>{" "}
                      <p className="mb-2">
                        You agree not to undertake any of the following actions:
                        <br/><b>a)</b> Reverse engineer, or attempt to reverse engineer or
                        disassemble any code or software from or on the Website
                        or Services;<br/><b>b)</b>  Violate the security of the Website or
                        Services through any unauthorized access, circumvention
                        of encryption or other security tools, data mining, or
                        interference to any host, user, or network.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> INDEMNIFICATION</h4>{" "}
                      <p className="mb-2">
                        You agree to defend and indemnify us and any of our
                        affiliates (if applicable) and hold us harmless against
                        any legal claims and demands, including reasonable
                        attorney's fees, which may arise from or relate to your
                        use or misuse of the Website or Services, your breach of
                        this Agreement, or your conduct or actions. You agree
                        that we shall be able to select its legal counsel and
                        may participate in its defense if we wish.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> EXCLUSION OF LIABILITY</h4>{" "}
                      <p className="mb-2">
                        You understand and agree that we (A) do not guarantee
                        the accuracy, completeness, validity, or timeliness of
                        information listed by us or any third parties; and (B)
                        shall not be responsible for any materials posted by us
                        or any third party. You shall use your judgment,
                        caution, and common sense in evaluating any prospective
                        methods or offers and any information provided by us or
                        any third party. Further, we shall not be liable for
                        direct, indirect consequential, or any other form of
                        loss or damage that may be suffered by a user through
                        the use of the JIMENEZMRDISCOUNT.com Website including
                        loss of data or information or any kind of financial or
                        physical loss or damage.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> SPAM POLICY</h4>{" "}
                      <p className="mb-2">
                        You are strictly prohibited from using the Website or
                        any of our Services for illegal spam activities,
                        including gathering email addresses and personal
                        information from others or sending any mass commercial
                        emails.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle">
                        {" "}
                        MODIFICATION & VARIATION
                      </h4>{" "}
                      <p className="mb-2">
                        We may, from time to time and at any time without notice
                        to you, modify this Agreement. You agree that we have
                        the right to modify this Agreement or revise anything
                        contained herein. You further agree that all
                        modifications to this Agreement are in full force and
                        effect immediately upon posting on the Website and that
                        modifications or variations will replace any prior
                        version of this Agreement unless prior versions are
                        specifically referred to or incorporated into the latest
                        modification or variation of this Agreement.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle">ENTIRE AGREEMENT</h4>{" "}
                      <p className="mb-2">
                        This Agreement constitutes the entire understanding
                        between the Parties concerning any use of this Website.
                        This Agreement supersedes and replaces all prior or
                        contemporaneous agreements or understandings, written or
                        oral, regarding the use of this Website.{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> SERVICE INTERRUPTIONS</h4>{" "}
                      <p className="mb-2">
                        We may need to interrupt your access to the Website to
                        perform maintenance or emergency services on a scheduled
                        or unscheduled basis. You agree that your access to the
                        Website may be affected by unanticipated or unscheduled
                        downtime, for any reason, but that we shall have no
                        liability for any damage or loss caused as a result of
                        such downtime.{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle">
                        {" "}
                        TERM, TERMINATION & SUSPENSION
                      </h4>{" "}
                      <p className="mb-2">
                        We may terminate this Agreement with you at any time for
                        any reason, with or without cause. We specifically
                        reserve the right to terminate this Agreement if you
                        violate any of the terms outlined herein, including, but
                        not limited to, violating the intellectual property
                        rights of us or a third party, failing to comply with
                        applicable laws or other legal obligations, and/or
                        publishing or distributing illegal material. If you have
                        registered for an account with Us, you may also
                        terminate this Agreement at any time by contacting us
                        and requesting termination. At the termination of this
                        Agreement, any provisions that would be expected to
                        survive termination by their nature shall remain in full
                        force and effect.
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> NO WARRANTIES</h4>{" "}
                      <p className="mb-2">
                        You agree that your use of the Website and Services is
                        at your sole and exclusive risk and that any Services
                        provided by us are on an "As Is" basis. We hereby
                        expressly disclaim any express or implied warranties of
                        any kind, including, but not limited to the implied
                        warranty of fitness for a particular purpose and the
                        implied warranty of merchantability. We make no
                        warranties that the Website or Services will meet your
                        needs or that the Website or Services will be
                        uninterrupted, error-free, or secure.{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <h4 className="subtitle"> GENERAL PROVISIONS</h4>{" "}
                     <ul style={{listStyle:'disc'}}>
                         <li> <b>JURISDICTION, VENUE & CHOICE OF LAW:</b><br/> The terms herein
                        will be governed by and construed by the laws of USA
                        without giving effect to any principles of conflicts of
                        law. The Courts of USA shall have exclusive jurisdiction
                        over any dispute arising from the use of the Website.
                        </li>
                        <li> <b>ASSIGNMENT:</b><br/>  This Agreement, or the rights granted
                        hereunder, may not be assigned, sold, leased, or
                        otherwise transferred in whole or part by you. Should
                        this Agreement, or the rights granted hereunder, be
                        assigned, sold, leased, or otherwise transferred by us,
                        the rights and liabilities of JIMENEZMRDISCOUNT.com will
                        bind and inure to any assignees, administrators,
                        successors, and executors.
                        </li>
                        <li> <b>SEVERABILITY:</b><br/>  If any part or
                        sub-part of this Agreement is held invalid or
                        unenforceable by a court of law or competent arbitrator,
                        the remaining parts and sub-parts will be enforced to
                        the maximum extent possible. In such a condition, the
                        remainder of this Agreement shall continue in full
                        force.
                        </li>
                        <li> <b>NO WAIVER:</b><br/>  If we fail to enforce any provision of
                        this Agreement, this shall not constitute a waiver of
                        any future enforcement of that provision or any other
                        provision. Waiver of any part or sub-part of this
                        Agreement will not constitute a waiver of any other part
                        or sub-part.
                        </li>
                        <li> <b> HEADINGS FOR CONVENIENCE ONLY:</b><br/> Headings of
                        parts and sub-parts under this Agreement are for
                        convenience and organization, only. Headings shall not
                        affect the meaning of any provisions of this Agreement.
                        </li>
                        <li> <b> NO AGENCY, PARTNERSHIP, OR JOINT VENTURE:</b><br/> No agency,
                        partnership, or joint venture has been created between
                        the Parties as a result of this Agreement. No Party has
                        any authority to bind the other to third parties.
                        </li>
                        <li> <b>FORCE
                        MAJEURE:</b><br/> We are not liable for any failure to perform
                        due to causes beyond its reasonable control including,
                        but not limited to, acts of God, acts of civil
                        authorities, acts of military authorities, riots,
                        embargoes, acts of nature, and natural disasters, and
                        other acts which may be due to unforeseen circumstances,
                        i.e., COVID-19!
                        </li>
                        <li> <b>ELECTRONIC COMMUNICATIONS PERMITTED:</b><br/> 
                        Electronic communications are permitted to both Parties
                        under this Agreement, including e-mail. For any
                        questions or concerns, please use the contact us form on
                        the website or email us contact@JIMENEZMRDISCOUNT.com
                        </li>
                     </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2"></div>

          {/* <div className="about-testimonials bg-light-2 pt-6 pb-6">
            <div className="container">
              <h2 className="title text-center mb-3">
                What Customer Say About Us
              </h2>
            </div>
          </div> */}
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(AboutOne);
